import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import CaseStudyCard from './CaseStudyCard';
import CaseStudyData from './CaseStudies.json';
import './CssStyles/CaseStudies.css'; // Import the CSS file for styling

const CaseStudies = () => {
    const [caseStudies, setCaseStudies] = useState([]);

    useEffect(() => {
        setCaseStudies(CaseStudyData);
    }, []);

    return (
        <div className="case-studies-container">
            <Helmet>
                <title>Case Studies - ByteBlock Technologies</title>
                <meta name="description" content="Explore our case studies to see how ByteBlock Technologies has helped businesses achieve their goals with innovative technology solutions." />
                <meta name="keywords" content="case studies, ByteBlock Technologies, technology solutions, business success stories" />
                <meta property="og:title" content="Case Studies - ByteBlock Technologies" />
                <meta property="og:description" content="Explore our case studies to see how ByteBlock Technologies has helped businesses achieve their goals with innovative technology solutions." />
                <meta property="og:url" content="https://www.byte-block.com/case-studies" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.byte-block.com/images/case-studies.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Case Studies - ByteBlock Technologies" />
                <meta name="twitter:description" content="Explore our case studies to see how ByteBlock Technologies has helped businesses achieve their goals with innovative technology solutions." />
                <meta name="twitter:image" content="https://www.byte-block.com/images/case-studies.jpg" />
                <link rel="canonical" href="https://www.byte-block.com/case-studies" />
            </Helmet>
            {caseStudies.map(caseStudy => (
                <CaseStudyCard key={caseStudy.id} caseStudy={caseStudy} />
            ))}
        </div>
    );
};

export default CaseStudies;