import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import LandingPage from './Components/LandingPage';
import Services from './Components/Services';
import CompanyProfile from './Components/CompanyProfile';
import ContactUs from './Components/ContactUs';
import Footer from './Components/Footer';

const HomePage = () => {
    useEffect(() => {
        // Add structured data for SEO
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.innerHTML = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "ByteBlock Technologies",
            "url": "https://www.byte-block.com",
            "potentialAction": {
                "@type": "SearchAction",
                "target": "https://www.byte-block.com/search?q={search_term_string}",
                "query-input": "required name=search_term_string"
            }
        });
        document.head.appendChild(script);
    }, []);

    return (
        <div>
            <Helmet>
                <title>ByteBlock Technologies - Building Your Digital Future</title>
                <meta name="description" content="ByteBlock Technologies builds your digital future block by block with tailored, cutting-edge solutions that efficiently meet diverse needs without compromising on style." />
                <meta name="keywords" content="technology, custom software development, API integration, data analytics, legacy system transformation, web design, technology consultancy, mumbai, Mumbai, india, India" />
                <meta property="og:title" content="ByteBlock Technologies - Building Your Digital Future" />
                <meta property="og:description" content="ByteBlock Technologies builds your digital future block by block with tailored, cutting-edge solutions that efficiently meet diverse needs without compromising on style." />
                <meta property="og:url" content="https://www.byte-block.com" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.byte-block.com/images/og-image.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="ByteBlock Technologies - Building Your Digital Future" />
                <meta name="twitter:description" content="ByteBlock Technologies builds your digital future block by block with tailored, cutting-edge solutions that efficiently meet diverse needs without compromising on style." />
                <meta name="twitter:image" content="https://www.byte-block.com/images/twitter-image.jpg" />
                <link rel="canonical" href="https://www.byte-block.com" />
            </Helmet>
            <div id="landing-page">
                <LandingPage />
            </div>
            <div id="services-profile">
                <Services />
            </div>
            <div id="company-profile">
                <CompanyProfile />
            </div>
            <div id="contact-us-app">
                <ContactUs />
            </div>
            <Footer />
        </div>
    );
}

export default HomePage;