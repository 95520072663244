import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import servicesData from './ServicesInfo.json';
import ServiceCard from './ServiceCard';
import Footer from './Footer';
import './CssStyles/ServicePage.css';

function ServicePage() {
    const { id } = useParams();
    const [service, setService] = useState(null);

    useEffect(() => {
        const serviceInfo = servicesData.find(service => service.id === parseInt(id));
        setService(serviceInfo);
        console.log(serviceInfo);

        if (serviceInfo) {
            // Add structured data for SEO
            const script = document.createElement('script');
            script.type = 'application/ld+json';
            script.innerHTML = JSON.stringify({
                "@context": "https://schema.org",
                "@type": "Service",
                "serviceType": serviceInfo.service,
                "provider": {
                    "@type": "Organization",
                    "name": "ByteBlock Technologies",
                    "url": "https://www.byte-block.com"
                },
                "url": `https://www.byte-block.com/service/${id}`,
                "description": serviceInfo.description,
                "image": `https://www.byte-block.com${serviceInfo['service-main-image']}`
            });
            document.head.appendChild(script);
        }
    }, [id]);

    if (!service) {
        return <div>Loading...</div>;
    }

    return (
        <div className='service-page'>
            <Helmet>
                <title>{service.service} - ByteBlock Technologies</title>
                <meta name="description" content={service.description.substring(0, 160)} />
                <meta name="keywords" content={`${service.service}, ByteBlock Technologies, ${service.service.split(' ').join(', ')}`} />
                <meta property="og:title" content={`${service.service} - ByteBlock Technologies`} />
                <meta property="og:description" content={service.description.substring(0, 160)} />
                <meta property="og:url" content={`https://www.byte-block.com/service/${id}`} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content={`https://www.byte-block.com${service['service-main-image']}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${service.service} - ByteBlock Technologies`} />
                <meta name="twitter:description" content={service.description.substring(0, 160)} />
                <meta name="twitter:image" content={`https://www.byte-block.com${service['service-main-image']}`} />
                <link rel="canonical" href={`https://www.byte-block.com/service/${id}`} />
            </Helmet>
            <div className='service-container'>
                <img src={service['service-main-image']} alt={service.service} className='service-image' />
                <div className='service-info'>
                    <h1>{service.service}</h1>
                    <p>{service.description}</p>
                </div>
            </div>
            <div className='extra-services-container'>
                {service['extra-services'].map((extraService, index) => (
                    <ServiceCard key={index} service={extraService} />
                ))}
            </div>
            <Footer />
        </div>
    );
}

export default ServicePage;