import React from 'react';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import './CssStyles/NotYet.css';

function NotYet() {
    return (
        <div className="not-yet-container">
            <Helmet>
                <title>Coming Soon - ByteBlock Technologies</title>
                <meta name="description" content="Stay tuned! Exciting new features and services are coming soon to ByteBlock Technologies." />
                <meta name="keywords" content="coming soon, ByteBlock Technologies, new features, new services" />
                <meta property="og:title" content="Coming Soon - ByteBlock Technologies" />
                <meta property="og:description" content="Stay tuned! Exciting new features and services are coming soon to ByteBlock Technologies." />
                <meta property="og:url" content="https://www.byte-block.com/products" />
                <meta property="og:type" content="website" />
                <meta property="og:image" content="https://www.byte-block.com/images/coming-soon.jpg" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content="Coming Soon - ByteBlock Technologies" />
                <meta name="twitter:description" content="Stay tuned! Exciting new features and services are coming soon to ByteBlock Technologies." />
                <meta name="twitter:image" content="https://www.byte-block.com/images/coming-soon.jpg" />
                <link rel="canonical" href="https://www.byte-block.com/products" />
            </Helmet>
            <h1 className="not-yet-title">Coming Soon</h1>
            <Footer />
        </div>
    );
}

export default NotYet;