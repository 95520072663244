import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Footer from './Footer';
import caseStudies from './CaseStudies.json'; // Adjust the path as necessary
import './CssStyles/CaseStudyDetails.css'; // Import the CSS file for styling

const CaseStudyDetails = () => {
    const { id } = useParams(); // Get the case study ID from the URL
    const [caseStudy, setCaseStudy] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    useEffect(() => {
        // Convert the id parameter to a number
        const caseStudyId = Number(id);
        // Find the case study by ID from the imported JSON data
        const foundCaseStudy = caseStudies.find(cs => cs.id === caseStudyId);
        setCaseStudy(foundCaseStudy);
    }, [id]);

    const openModal = (image) => {
        setSelectedImage(image);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedImage(null);
    };

    if (!caseStudy) {
        return <div>Loading...</div>;
    }

    return (
        <div className="case-study-details">
            <Helmet>
                <title>{caseStudy.title} - ByteBlock Technologies</title>
                <meta name="description" content={caseStudy.shortDescription.substring(0, 160)} />
                <meta name="keywords" content={`${caseStudy.title}, ByteBlock Technologies, ${caseStudy.tags.join(', ')}`} />
                <meta property="og:title" content={`${caseStudy.title} - ByteBlock Technologies`} />
                <meta property="og:description" content={caseStudy.shortDescription.substring(0, 160)} />
                <meta property="og:url" content={`https://www.byte-block.com/case-study/${id}`} />
                <meta property="og:type" content="article" />
                <meta property="og:image" content={`https://www.byte-block.com${caseStudy.clientLogo}`} />
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={`${caseStudy.title} - ByteBlock Technologies`} />
                <meta name="twitter:description" content={caseStudy.shortDescription.substring(0, 160)} />
                <meta name="twitter:image" content={`https://www.byte-block.com${caseStudy.clientLogo}`} />
                <link rel="canonical" href={`https://www.byte-block.com/case-study/${id}`} />
            </Helmet>
            <a href={caseStudy.websiteUrl} target="_blank" rel="noopener noreferrer">
                <img src={caseStudy.clientLogo} alt={caseStudy.client} className="case-study-logo" />
            </a>
            <h1>{caseStudy.title}</h1>
            <div className="case-study-info">
                <p className="case-study-client">{caseStudy.client}</p>
                <p className="case-study-location">{caseStudy.location}</p>
                <p className="case-study-industry">{caseStudy.industry}</p>
            </div>
            <p className="case-study-description">{caseStudy.shortDescription}</p>
            <div className="case-study-section">
                <h2>Challenge</h2>
                <p>{caseStudy.challenge}</p>
            </div>
            <div className="case-study-section">
                <h2>Solution</h2>
                <p>{caseStudy.solution.overview}</p>
                <ul>
                    {caseStudy.solution.keyFeatures.map((feature, index) => (
                        <li key={index}>{feature}</li>
                    ))}
                </ul>
            </div>
            <div className="case-study-section">
                <h2>Technical Details</h2>
                <div className="technical-details">
                    <p><strong>Front End:</strong> {caseStudy.technicalDetails.frontEnd.join(', ')}</p>
                    <p><strong>Back End:</strong> {caseStudy.technicalDetails.backEnd.join(', ')}</p>
                    <p><strong>Database:</strong> {caseStudy.technicalDetails.database}</p>
                    <p><strong>Hosting:</strong> {caseStudy.technicalDetails.hosting}</p>
                    <p><strong>Other Tools:</strong> {caseStudy.technicalDetails.otherTools.join(', ')}</p>
                </div>
            </div>
            <div className="case-study-section">
                <h2>Outcomes</h2>
                <div className="outcomes">
                    <div>
                        <p><strong>Deliverables:</strong></p>
                        <ul>
                            {caseStudy.outcomes.deliverables.map((deliverable, index) => (
                                <li key={index}>{deliverable}</li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <p><strong>Results:</strong></p>
                        <ul>
                            {caseStudy.outcomes.results.map((result, index) => (
                                <li key={index}>{result}</li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <div className="case-study-section">
                <h2>Testimonial</h2>
                <blockquote>
                    <p>"{caseStudy.testimonial.quote}"</p>
                    <footer>- {caseStudy.testimonial.author}, {caseStudy.testimonial.designation}</footer>
                </blockquote>
            </div>
            <div className="case-study-section">
                <h2>Tags</h2>
                <div className="case-study-tags">
                    {caseStudy.tags.map((tag, index) => (
                        <span key={index} className="case-study-tag">{tag}</span>
                    ))}
                </div>
            </div>
            <div className="case-study-images">
                {caseStudy.projectImages.gallery.map(image => (
                    <img
                        key={image.id}
                        src={image.url}
                        alt={image.caption}
                        className="case-study-image"
                        onClick={() => openModal(image.url)}
                    />
                ))}
            </div>
            {isModalOpen && (
                <div className="modal" onClick={closeModal}>
                    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                        <span className="close" onClick={closeModal}>&times;</span>
                        <img src={selectedImage} alt="Enlarged view" className="modal-image" />
                    </div>
                </div>
            )}
            <Link to="/case-studies" className="back-link">Back to Case Studies</Link>
            <Footer />
        </div>
    );
};

export default CaseStudyDetails;